import AppHttpClient from "../../../core/http/index";

const getSubjectList = () => AppHttpClient.post("/get_list_subject", {});

const getExamList = (body: { subject: string, area: string }) =>
  AppHttpClient.post("/get_list_exam", body);

const newMarking = (body: FormData, options: any) =>
  AppHttpClient.postFormData("/marking_aichi", body, options);

const downloadCsvFile = (csv_name: string) =>
  AppHttpClient.post(
    `/download_csv?csv_name=${encodeURIComponent(csv_name)}`,
    {}
  );

const getExampleLink = (template_name: string) =>
  AppHttpClient.post(
    `/download_template?template_name=${encodeURIComponent(template_name)}`,
    {}
  );

const getLastestFile = (path: string) =>
  AppHttpClient.get(
    `/get_lastest_file?path=${encodeURIComponent(path)}`,
    {}
  );

const ExamService = {
  getExamList,
  getSubjectList,
  newMarking,
  downloadCsvFile,
  getExampleLink,
  getLastestFile,
};
export default ExamService;
