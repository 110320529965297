import React, { FC, ReactNode } from "react";
import "./index.scss";
import AppNavbar from "components/AppNavbar";

interface Props {
  children: ReactNode;
}

const AppLayout: FC<Props> = ({ ...props }) => {
  const { children } = props;

  return (
    <div className="ui-layout">
      <div className="ui-layout__navbar">
        <AppNavbar />
      </div>
      <div className="ui-layout__children">{children}</div>
    </div>
  );
};

export default AppLayout;
