import classNames from "classnames";
import { FC, memo } from "react";
import { Button, ButtonProps } from "react-bootstrap";
import "./index.scss";

interface ButtonProp extends ButtonProps {
  text: string | any;
  btntype: "primary" | "secondary";
}

const AppButton: FC<ButtonProp> = memo(({ ...props }) => {
  const { text, btntype, className } = props;
  return (
    <>
      <Button
        {...props}
        className={classNames("app-button", `button-${btntype}`, className)}
      >
        {text}
      </Button>
    </>
  );
});

export default AppButton;
