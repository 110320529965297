import { FC, memo } from "react";
import "./index.scss";

interface Props {
  children?: any;
}

const AppContent: FC<Props> = memo(({ ...props }) => {
  const { children } = props;

  return <div className="ui-content">{children}</div>;
});

export default AppContent;
