export const PATH_NAME = {
  DEFAULT: "/",
  OTHER: "*",
  LOGIN: "/login",
  AICHI: "/aichi",
  CHIBA: "/chiba",
  ANJO: "/anjo",
  ADMIN: "/admin",
};

export const AREA = {
  AICHI: "aichi",
  ANJO: "anjo",
  CHIBA: "chiba",
};

const ACCEPT_ANSWER = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
];

const ACCEPT_ANSWER_SHEET = ["image/jpeg", "application/pdf"];

const ACCEPT_SAMPLE_MARKSHEET = ["application/pdf"];

const ACCEPT_ANSWER_SHEET_DROP_ZONE = {
  "image/jpeg": [".jpeg", ".jpg"],
  "application/pdf": [".pdf"],
};

const ACCEPT_SAMPLE_MARKSHEET_DROP_ZONE = {
  "application/pdf": [".pdf"],
};

const ACCEPT_ANSWER_DROP_ZONE = {
  "application/vnd.ms-excel": [],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
};

const NAME_OF_LINK_SAMPLE = {
  AICHI_PDF: "data/manual/aichi",
  ANJO_PDF: "data/manual/anjo",
  CHIBA_PDF: "data/manual/chiba",
  EXCEL: "愛知マークシート_個票用エクセル.xlsx",
};

export {
  ACCEPT_ANSWER,
  ACCEPT_ANSWER_SHEET,
  ACCEPT_ANSWER_SHEET_DROP_ZONE,
  ACCEPT_ANSWER_DROP_ZONE,
  NAME_OF_LINK_SAMPLE,
  ACCEPT_SAMPLE_MARKSHEET_DROP_ZONE,
  ACCEPT_SAMPLE_MARKSHEET,
};
