import { FC, Suspense } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import { AppRoute } from "./routes.model";
import Homepage from "pages/Homepage";
import Chiba from "pages/Chiba";
import Anjo from "pages/Anjo";
import { PATH_NAME } from "configs";
import AppLayout from "components/AppLayout";
import AdminPage from "pages/Admin";

// App routes
const appRoutes: AppRoute[] = [
  {
    path: PATH_NAME.CHIBA,
    element: <Chiba />,
    requiredAuth: false,
    mainLayout: true,
  },
  {
    path: PATH_NAME.AICHI,
    element: <Homepage />,
    requiredAuth: false,
    mainLayout: true,
  },
  {
    path: PATH_NAME.ANJO,
    element: <Anjo />,
    requiredAuth: false,
    mainLayout: true,
  },
  // {
  //   path: PATH_NAME.ADMIN,
  //   element: <AdminPage />,
  //   requiredAuth: false,
  //   mainLayout: true,
  // },
];

const renderRoutes = () => {
  return (
    appRoutes &&
    appRoutes.map((appRoute, index) => {
      const element = appRoute.mainLayout ? (
        <AppLayout>{appRoute.element}</AppLayout>
      ) : (
        <RenderLayout>{appRoute.element}</RenderLayout>
      );
      return <Route key={index} path={appRoute.path} element={element} />;
    })
  );
};

const RenderLayout = ({ children }: { children: JSX.Element }) => {
  return children;
};

const AppRoutes: FC = () => {
  return (
    <Suspense>
      <BrowserRouter>
        <Routes>
          {renderRoutes()}
          <Route path="*" element={<Navigate to={PATH_NAME.AICHI} replace />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default AppRoutes;
