import React, { FC, memo, useEffect, useRef, useState } from "react";
import Dropzone, { Accept } from "react-dropzone";
import { IoMdClose } from "react-icons/io";
import { BsTrash } from "react-icons/bs";
import { HiOutlineUpload } from "react-icons/hi";
import "./index.scss";
import AppButton from "components/AppButton";

interface FileProp {
  textGuide: any;
  acceptFile: Accept;
  uploadPercent: number;
  isMultiple: boolean;
  answerFile?: File;
  setFileValues: (value: File[]) => void;
}

const DragDropFile: FC<FileProp> = memo(({ ...props }) => {
  const {
    textGuide,
    isMultiple,
    setFileValues,
    uploadPercent,
    acceptFile,
    answerFile,
  } = props;
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const directoryRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (directoryRef.current !== null) {
      directoryRef.current.setAttribute("directory", "");
      directoryRef.current.setAttribute("webkitdirectory", "");
    }
  }, [directoryRef]);

  const handleOldFile = (files: File[]) => {
    const duplicateFiles = files.filter((newFile) => {
      const existsInSelectedFiles = selectedFiles.some(
        (oldFile) => oldFile.name === newFile.name
      );
      return existsInSelectedFiles;
    });
    const oldSelectedFiles = selectedFiles.filter(
      (oldFile) => !duplicateFiles.some((file) => file.name === oldFile.name)
    );
    return oldSelectedFiles;
  };

  const onDropFiles = (files: File[]) => {
    if (files.length > 0) {
      if (isMultiple) {
        handleOldFile(files);
        setSelectedFiles([...handleOldFile(files), ...files]);
      } else {
        setSelectedFiles(files);
      }
    }
  };

  const handleRemoveFile = (file: File) => {
    setSelectedFiles((preState) =>
      preState?.filter((pre) => pre.name !== file.name)
    );
  };

  useEffect(() => {
    setFileValues(selectedFiles);
  }, [selectedFiles, setFileValues]);

  useEffect(() => {
    if (!answerFile) {
      setSelectedFiles([]);
    }
  }, [answerFile]);

  function sortFilesByName() {
    const sortedFiles: File[] = [];
    selectedFiles.forEach((file) => {
      sortedFiles.push(file);
    });
    sortedFiles.sort((a: any, b: any) => a.name.localeCompare(b.name));

    return sortedFiles;
  }

  return (
    <div className="drop-zone__container">
      <Dropzone
        onDrop={onDropFiles}
        multiple={isMultiple}
        disabled={uploadPercent > 0}
        accept={acceptFile}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps({ className: "drop-zone" })}>
              <input {...getInputProps()} />
              <span className="text-guide">{textGuide}</span>
            </div>

            <AppButton
              text={
                <>
                  &nbsp;
                  <HiOutlineUpload />
                  &nbsp; フォルダ
                </>
              }
              btntype="primary"
              type="button"
              className="btn-upload__folder"
              onClick={() => {
                directoryRef.current && (directoryRef.current as any)?.click();
              }}
            />
            <input
              hidden
              accept="image/jpeg"
              multiple
              type="file"
              ref={directoryRef}
              onChange={(e) => {
                const files: File[] = Array.from(e.target.files as any);
                setSelectedFiles([...handleOldFile(files), ...files]);
              }}
            />

            {selectedFiles?.length > 1 && (
              <div className="file-remove-all">
                <span onClick={() => setSelectedFiles([])}>
                  <BsTrash />
                  All
                </span>
              </div>
            )}

            {selectedFiles?.length > 0 && (
              <div className="file-container">
                {sortFilesByName().map((file, idx) => (
                  <div className="file-container__selected" key={idx}>
                    {file.name}
                    <span
                      className="file-container__icon-remove"
                      onClick={() => handleRemoveFile(file)}
                    >
                      <IoMdClose />
                    </span>
                  </div>
                ))}
              </div>
            )}
          </section>
        )}
      </Dropzone>
    </div>
  );
});

export default DragDropFile;
